import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { EventFormOccurrenceUtil } from "../s25-event-creation-form/occurrences/event.form.occurrence.util";
import { EventStateConst } from "../../services/event.state.change.service";
import {
    CustomAttribute,
    S25CustomAttributeItemFormatter,
} from "../s25-custom-attribute/s25.custom.attribute.item.formatter";
import { Event } from "../../pojo/Event";
import { S25ItemI } from "../../pojo/S25ItemI";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";

interface PreviewItem {
    uuid: string;
    derivedItem?: boolean;
    display: string;
    required?: boolean;
    itemId?: number;
    itemTypeId?: string;
    itemName?: string;
    multiselectBean?: MultiselectModelI;
    data?: string | number | boolean | PreviewItem | PreviewItem[];
    role_name?: string;
    standardInput?: boolean;
    forceHidden?: boolean;
    attachmentModel?: { files: S25ItemI[] };
    custAttrsModel?: { item: CustomAttribute.Attribute[] };
    checkListModel?: { selectedItems: S25ItemI[] };
    scrollTo?: () => void;
    focus?: () => void;
}

interface Occurrence {
    evStartDtDate: string;
    evStartDt: string;
    evEndDt: string;
    locations: S25ItemI[];
    resources: S25ItemI[];
}

interface ValidationError {
    message: string;
}

@TypeManagerDecorator("s25-ng-event-form-preview")
@Component({
    selector: "s25-ng-event-form-preview",
    template: `
        <div>
            @if (init) {
                <div>
                    @for (item of previewItems; track $index) {
                        <div class="rose-preview-item--wrapper">
                            <!-- skip derived elements like the create org button -->
                            @if (!item.derivedItem) {
                                <div class="rose-preview-item">
                                    @if (!noLabel) {
                                        <a href="javascript:void(0)" class="ngBold" (click)="itemClick(item)">
                                            <h2>
                                                {{ item.display }}
                                                @if (item.required) {
                                                    <span class="c-required"> &nbsp;(Required) </span>
                                                }
                                            </h2>
                                        </a>
                                    }

                                    <div class="rose-preview-item--content">
                                        @switch (item.uuid) {
                                            @case ("name") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }

                                            @case ("title") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }

                                            @case ("type") {
                                                <div>
                                                    <span>{{ truncate(item.data?.itemName) }}</span>
                                                </div>
                                            }

                                            @case ("primaryOrg") {
                                                <div>
                                                    <span>{{ truncate(item.data?.itemName) }}</span>
                                                </div>
                                            }

                                            @case ("otherOrgs") {
                                                <div>
                                                    <span>
                                                        @for (
                                                            subItem of item.multiselectBean.selectedItems;
                                                            track $index
                                                        ) {
                                                            {{ truncate(subItem.itemName) }}{{ $last ? "" : ", " }}
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("expected") {
                                                <div>
                                                    <span>{{ item.data }}</span>
                                                </div>
                                            }

                                            @case ("registered") {
                                                <div>
                                                    <span>{{ item.data }}</span>
                                                </div>
                                            }

                                            @case ("description") {
                                                <div>
                                                    <span [innerHTML]="toString(item.data) | safeHTML"></span>
                                                </div>
                                            }

                                            @case ("occurrences") {
                                                <div>
                                                    @if (consolidatedOccObj) {
                                                        <span>
                                                            @for (occ of occurrences; track $index) {
                                                                <div>
                                                                    <div>{{ occ.evStartDtDate }}</div>
                                                                    <div>{{ occ.evStartDt }} - {{ occ.evEndDt }}</div>

                                                                    @if (occ.locations.length) {
                                                                        <div>
                                                                            Locations:
                                                                            <span>
                                                                                @for (
                                                                                    obj of occ.locations;
                                                                                    track $index
                                                                                ) {
                                                                                    {{ truncate(obj.itemName)
                                                                                    }}{{ $last ? "" : ", " }}
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    }

                                                                    @if (occ.resources.length) {
                                                                        <div>
                                                                            Resources:
                                                                            <span>
                                                                                @for (
                                                                                    obj of occ.resources;
                                                                                    track $index
                                                                                ) {
                                                                                    {{ truncate(obj.itemName)
                                                                                    }}{{ $last ? "" : ", " }}
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    }

                                                                    @if (!$last) {
                                                                        <div class="ngHorizontalLine"></div>
                                                                    }
                                                                </div>
                                                            }
                                                        </span>
                                                    } @else {
                                                        <span>
                                                            @for (occ of occurrences; track $index) {
                                                                <div>
                                                                    <div>{{ occ.evStartDtDate }}</div>
                                                                    <div>{{ occ.evStartDt }} - {{ occ.evEndDt }}</div>
                                                                    @if (!$last) {
                                                                        <div class="ngHorizontalLine"></div>
                                                                    }
                                                                </div>
                                                            }
                                                        </span>
                                                    }
                                                </div>
                                            }

                                            @case ("space") {
                                                <div>
                                                    @if (!consolidatedOccObj) {
                                                        <span>
                                                            @if (formModel.locations.length) {
                                                                <div>
                                                                    Locations:
                                                                    <span>
                                                                        @for (
                                                                            obj of formModel.locations;
                                                                            track $index
                                                                        ) {
                                                                            {{ truncate(obj.itemName)
                                                                            }}{{ $last ? "" : ", " }}
                                                                        }
                                                                    </span>
                                                                </div>
                                                            }
                                                        </span>
                                                    }
                                                </div>
                                            }

                                            @case ("resource") {
                                                <div>
                                                    @if (!consolidatedOccObj) {
                                                        <span>
                                                            @if (formModel.resources.length) {
                                                                <div>
                                                                    Resources:
                                                                    <span>
                                                                        @for (
                                                                            obj of formModel.resources;
                                                                            track $index
                                                                        ) {
                                                                            {{ truncate(obj.itemName)
                                                                            }}{{ $last ? "" : ", " }}
                                                                        }
                                                                    </span>
                                                                </div>
                                                            }
                                                        </span>
                                                    }
                                                </div>
                                            }

                                            @case ("files") {
                                                <div>
                                                    <span>
                                                        @for (subItem of item.attachmentModel?.files; track $index) {
                                                            {{ truncate(subItem.itemName) }}{{ $last ? "" : ", " }}
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("customAttributes") {
                                                <div>
                                                    <span>
                                                        @for (subItem of item.custAttrsModel?.item; track $index) {
                                                            <p
                                                                [id]="subItem.itemId"
                                                                class="rose-preview-item--customAttr-label"
                                                            >
                                                                <s25-ng-link-parser
                                                                    [text]="truncate(subItem.itemLabel)"
                                                                ></s25-ng-link-parser>
                                                            </p>
                                                            <p
                                                                [attr.aria-labelledby]="subItem.itemId"
                                                                class="c-padding-left--half"
                                                            >
                                                                {{ formatCustAttrData(subItem) }}
                                                            </p>
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("contacts") {
                                                <div>
                                                    <div>
                                                        @for (subItem of $any(item.data); track $index) {
                                                            <p
                                                                id="{{ subItem.role_name }}"
                                                                class="rose-preview-item--contactRole-label"
                                                            >
                                                                {{ truncate(subItem.role_name) }}:
                                                            </p>
                                                            <p
                                                                [attr.aria-labelledby]="subItem.role_name"
                                                                class="c-padding-left--half"
                                                            >
                                                                {{ truncate(subItem.data?.itemName) }}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            @case ("categories") {
                                                <div>
                                                    <span>
                                                        @for (
                                                            subItem of item.multiselectBean?.selectedItems;
                                                            track $index
                                                        ) {
                                                            {{ truncate(subItem.itemName) }}{{ $last ? "" : ", " }}
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("requirements") {
                                                <div>
                                                    <span>
                                                        @for (
                                                            subItem of item.checkListModel?.selectedItems;
                                                            track $index
                                                        ) {
                                                            {{ truncate(subItem.itemName) }}{{ $last ? "" : ", " }}
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("publishToCalendar") {
                                                <div>
                                                    <span>
                                                        @for (
                                                            subItem of item.checkListModel?.selectedItems;
                                                            track $index
                                                        ) {
                                                            {{ truncate(subItem.itemName) }}{{ $last ? "" : ", " }}
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            @case ("comment") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }

                                            @case ("confirmationNote") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }

                                            @case ("internalNote") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }

                                            @case ("state") {
                                                <div>
                                                    <span>{{ EventStateConst.stateMap[item.data] }}</span>
                                                </div>
                                            }

                                            @case ("createAndRelate") {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }
                                            @default {
                                                <div>
                                                    <span>{{ truncate(item.data) }}</span>
                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                                @if (validation?.length) {
                                    <div class="rose-form--error-text ngRed">{{ validation[0].message }}</div>
                                }
                            }
                        </div>
                    }
                </div>
            }
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormPreviewComponent implements OnInit {
    private static MAX_STRING_LEN = 120;
    private static CATEGORICAL_MAP: { [key: string]: string } = {
        createAndRelateNew: "Create and Relate New Blank Event",
        createAndRelateCopy: "Create and Relate Copy of Current Event",
        keepEditing: "Save and Continue Editing Current Event",
        createAnother: "Save and Create Another Event",
    };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    @Input() formModel: Event.Form;
    @Input() itemUuid: string;
    @Input() noLabel: boolean;
    @Input() consolidatedOccObj: boolean;
    @Input() validation: ValidationError[] = [];

    protected readonly EventStateConst = EventStateConst;
    timeFormat: string;
    dateFormat: string;
    dateTimeFormat: string;
    occurrences: Occurrence[];
    previewItems: PreviewItem[];
    init = false;

    itemClick = (item: PreviewItem) => {
        item.scrollTo && item.scrollTo();
        setTimeout(() => {
            item.focus();
        });
    };

    formatCustAttrData = (subItem: CustomAttribute.Attribute) => {
        const value = S25Util.coalesce(subItem.itemName, subItem.data);
        let formatted = S25CustomAttributeItemFormatter.getFormat(subItem, value);

        if (subItem.itemTypeId === "T") {
            formatted = S25Datefilter.transform(`1970-01-01T${value}`, this.timeFormat);
        } else if (subItem.itemTypeId === "E") {
            formatted = S25Datefilter.transform(value, this.dateTimeFormat);
        } else if (subItem.itemTypeId === "D") {
            formatted = S25Datefilter.transform(value, this.dateFormat);
        } else if (subItem.itemTypeId === "S" && subItem.multi_val) {
            try {
                formatted = S25Util.array
                    .forceArray(JSON.parse(value))
                    .map(({ item }) => item)
                    .join("; ");
            } catch (err) {
                formatted = value;
            }
        }

        return this.truncate(formatted);
    };

    toString = (str: string | number | boolean) => {
        str = S25Util.coalesce(str, "") + "";
        return str === "true" ? "Yes" : str === "false" ? "No" : EventFormPreviewComponent.CATEGORICAL_MAP[str] || str;
    };

    truncate = (str: string | number | boolean) => {
        str = this.toString(str);
        return (
            str.substring(0, EventFormPreviewComponent.MAX_STRING_LEN) +
            (str.length > EventFormPreviewComponent.MAX_STRING_LEN ? "..." : "")
        );
    };

    async ngOnInit() {
        const resp = await S25Util.all({
            timeFormat: UserprefService.getS25Timeformat(),
            dateFormat: UserprefService.getS25Dateformat(),
            dateTimeFormat: UserprefService.getS25DateTimeformat(),
        });

        this.timeFormat = resp.timeFormat;
        this.dateFormat = resp.dateFormat;
        this.dateTimeFormat = resp.dateTimeFormat;

        await this.update();
        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    async update() {
        this.previewItems = this.formModel.items.filter((item: PreviewItem) => {
            //if consolidated then skip space/resource sections
            const skip = this.consolidatedOccObj && ["space", "resource"].indexOf(item.uuid) > -1;
            return !skip && (!this.itemUuid || item.uuid === this.itemUuid) && !item.forceHidden;
        });

        this.occurrences = [];
        this.formModel.occurrences.forEach((occ) => {
            let displayOcc = {
                evStartDtDate: S25Datefilter.transform(occ.evStartDt, this.dateFormat),
                evStartDt: S25Datefilter.transform(occ.evStartDt, this.timeFormat),
                evEndDt:
                    S25Datefilter.transform(occ.evEndDt, this.timeFormat) +
                    (S25Util.date.diffDays(occ.evStartDt, occ.evEndDt) === 0
                        ? ""
                        : " (" + S25Datefilter.transform(occ.evEndDt, this.dateFormat) + ")"),
                locations: EventFormOccurrenceUtil.getOccLocations(this.formModel, occ, true),
                resources: EventFormOccurrenceUtil.getOccResources(this.formModel, occ, true),
            };
            this.occurrences.push(displayOcc);
        });

        this.cd.detectChanges();
    }
}
